import { useMemo } from 'react';

import { useWordPressContext } from 'contexts/wordpress-provider';

export type StatesListItem = {
  label: string;
  value: string;
};

export type StatesList = StatesListItem[];

export function useStatesList(isRenewal = false): StatesList {
  const { statePages, renewalPages } = useWordPressContext();
  const statePagesList = isRenewal ? renewalPages : statePages;

  return useMemo(() => {
    if (statePagesList?.length > 0) {
      return statePagesList.map(({ code, label }) => ({
        label,
        value: code,
      }));
    }

    return [];
  }, [statePagesList]);
}
