type ShareType = 'simple' | 'full';

type ShareImagesFull = { url: string; width: number; height: number };

type ReturnTypeBasedOnShareType<T> = T extends 'full'
  ? ShareImagesFull
  : string;

export function useShareImages<T extends ShareType>({
  title,
  type,
}: {
  title: string;
  type: T;
}): ReturnTypeBasedOnShareType<T> {
  const shareImage = {
    width: 1200,
    height: 630,
    url: encodeURI(
      `${
        process.env.NEXT_PUBLIC_VERCEL_URL
          ? 'https://' + process.env.NEXT_PUBLIC_VERCEL_URL
          : ''
      }/api/og${title ? '?title=' + title : ''}`,
    ),
  };

  return (
    type === 'full' ? shareImage : shareImage.url
  ) as ReturnTypeBasedOnShareType<T>;
}
