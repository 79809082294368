import type { GetStaticProps } from 'next';
import type { Hero } from 'types';
import type { FrontPage } from 'lib/queries/pages';
import React from 'react';
import {
  Paragraph,
  Wrap,
  ButtonClose,
  useHeadingStyles,
} from '@leafwell/components';
import Image from 'next/image';
import Blocks from 'components/blocks';
import Figure from 'components/figure';
import PageWrap from 'components/page-wrap';
import StateSelect from 'components/state-select';
import PresenceJson from 'components/structured-data/presence';
import { getFrontPage } from 'lib/queries/pages';
import {
  useFeatureFlagEnabled,
  useFeatureFlagVariantKey,
} from 'posthog-js/react';
import { useCurrentState } from 'hooks/useCurrentState';
import { useWordPressContext } from 'contexts/wordpress-provider';
import { useEventsTracker } from 'contexts/eventsTracker';

const SlideUpMessage: React.FC<{
  visible: boolean;
  variant: 'code' | 'price';
  toggleMessage: (toggle: boolean) => void;
}> = ({ visible, variant, toggleMessage }) => {
  const stateCode = useCurrentState();
  const { cardPage, statePages } = useWordPressContext();
  const { trackEvent } = useEventsTracker();
  const hasStateCode = stateCode && stateCode !== '';
  let url: string;
  let price: string;

  if (hasStateCode) {
    const statePage = statePages.find(({ code }) => code === stateCode);
    url = statePage.uri;

    if (statePage.price) {
      price = statePage.price;
    }
  } else {
    url = cardPage?.uri;
  }

  function handleClick() {
    trackEvent({
      event: 'select_content',
      content_type:
        variant === 'price'
          ? price
            ? variant
            : `${variant}_fallback`
          : variant,
      item_id: 'page',
      origin: 'header_experiment',
    });
  }

  return (
    <div
      className={[
        'relative md:fixed md:bottom-8 md:rounded-lg bg-[#FFB800] z-10 transition-all duration-500',
        visible
          ? 'max-h-80 md:max-h-full right-0 md:right-8'
          : 'max-h-0 md:max-h-full md:-right-8 opacity-0',
      ].join(' ')}
    >
      <div className="flex justify-between items-center py-6 px-6 md:gap-x-8">
        <div className="flex flex-col gap-y-2">
          {variant === 'code' ? (
            <>
              <p className="text-xs font-medium">Limited Offer</p>
              <p className="text-2xl">
                <span className="font-medium">Save 10% on</span>
                <br />
                Your Medical Card
              </p>
              <div className="flex items-center gap-x-3">
                <a className="underline" href={url} onClick={handleClick}>
                  Use Code
                </a>
                <a
                  className="bg-white px-3 py-1 font-medium rounded-xl"
                  href={url}
                  onClick={handleClick}
                >
                  SAVE10
                </a>
              </div>
            </>
          ) : (
            <>
              <div>
                <p className="text-xl font-medium">
                  {price
                    ? `Get Approved for Just $${price}`
                    : 'Get Approved Today'}
                </p>
                <p className="text-xl">Limited Offer</p>
              </div>
              <a href={url} className="underline" onClick={handleClick}>
                Start Now
              </a>
            </>
          )}
        </div>
        <Image
          src="promotion.svg"
          alt=""
          width={variant === 'code' ? 126 : 100}
          height={variant === 'code' ? 118 : 80}
        />
        <ButtonClose
          onClick={() => toggleMessage(false)}
          className="w-6 mt-2 absolute top-4 right-2"
          size={12}
        />
      </div>
    </div>
  );
};

const HomeHero: React.FC<Hero> = ({ image, title, steps }) => {
  const headingStyles = useHeadingStyles('1').replace(
    /(\s|^)text-([a-zA-Z0-9]*)/,
    ' text-3xl',
  );
  const limitedOfferVariant = useFeatureFlagVariantKey('limited-time-offer');
  const isLimitedOfferEnabled = useFeatureFlagEnabled('limited-time-offer');
  const [showMessage, setShowMessage] = React.useState(true);

  return (
    <>
      {isLimitedOfferEnabled && limitedOfferVariant !== 'control' ? (
        <SlideUpMessage
          visible={showMessage}
          variant={limitedOfferVariant as 'price' | 'code'}
          toggleMessage={setShowMessage}
        />
      ) : null}
      <section className="relative bg-gradient-to-r from-gray-200 via-gray-200 to-lilac-100 xl:-mt-40 xl:pt-40 before:absolute before:md:w-3/4 before:h-full before:md:right-0 before:md:bottom-0 before:bg-gradient-to-b before:from-lilac-100 before:to-green-200 before:gradient-mask-l-0">
        <Wrap
          spacer={false}
          className="lg:grid lg:grid-cols-3 lg:gap-x-3 pt-6 xl:pt-0 pb-12 lg:pb-0 relative lg:min-h-[540px] xl:min-h-[580px] transition-all"
        >
          <div className="lg:self-center grid gap-y-6 xl:gap-y-12 lg:pb-12 col-span-2">
            <div>
              <h1
                className={headingStyles}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </div>
            <StateSelect showLoginCta={true} />
            {steps && (
              <ul className="grid sm:grid-cols-2 gap-x-6 gap-y-6 xl:pt-3">
                {steps.map(({ title, description }, key) => (
                  <li
                    key={key}
                    className="grid grid-cols-2-auto gap-x-3 gap-y-1 items-start"
                  >
                    <Image
                      height={20}
                      width={20}
                      priority={true}
                      src="/check-gradient.svg"
                      alt=""
                      className="row-span-2 mt-list-offset"
                    />
                    <Paragraph
                      size="large"
                      className="font-medium"
                      dangerouslySetInnerHTML={{ __html: title }}
                    />
                    <Paragraph
                      size="small"
                      variant="medium"
                      dangerouslySetInnerHTML={{ __html: description }}
                      tagName="article"
                    />
                  </li>
                ))}
              </ul>
            )}
          </div>
          {image && (
            <Figure
              alt={image.alt}
              src={image.src}
              width={image.width}
              height={image.height}
              priority={true}
              className="hidden lg:block lg:self-end"
              sizes="(max-width: 1023px) 0vw, (max-width: 1200px) 33vw, 33vw"
            />
          )}
        </Wrap>
      </section>
    </>
  );
};
const Home: React.FC<FrontPage> = ({
  page: {
    title,
    seo,
    acf: { pageType, blocks, hero },
  },
  partnersReviews,
}) => {
  return (
    <PageWrap seo={seo} title={title} pageType={pageType}>
      <PresenceJson />
      <HomeHero {...hero} />
      <Blocks
        blocks={blocks}
        pageType={pageType}
        partnersReviews={partnersReviews}
      />
    </PageWrap>
  );
};

export const getStaticProps: GetStaticProps = async context => {
  return await getFrontPage(context);
};

export default Home;
