import type { NextResponse } from 'next/server';
import type { CookieSerializeOptions } from 'cookie';
import type { CookieAttributes } from 'js-cookie';
import type { ServerRequest } from './cookie';

import Cookies from 'js-cookie';

import { getCookieServer } from './cookie';

export const STATE_COOKIE_NAME = 'LEAFWELL_STATE';

export function getCookieParams(): CookieSerializeOptions {
  const expirationDate = new Date();

  expirationDate.setDate(expirationDate.getDate() + 365);

  return {
    expires: expirationDate,
    secure: process.env.NODE_ENV !== 'development',
  };
}

export function setStateCookie(value: string): void {
  Cookies.set(STATE_COOKIE_NAME, value, getCookieParams() as CookieAttributes);
}

export function removeStateCookie(): void {
  Cookies.remove(STATE_COOKIE_NAME);
}

export function getStateCookie(): string | undefined {
  return Cookies.get(STATE_COOKIE_NAME);
}

export function getStateCookieServer(req: ServerRequest): string {
  return getCookieServer(req, STATE_COOKIE_NAME);
}

export function setStateCookieServer(
  res: NextResponse,
  value: string,
): NextResponse {
  res.cookies.set(STATE_COOKIE_NAME, value, getCookieParams());
  const cookie = res.cookies.get(STATE_COOKIE_NAME);

  return res;
}
