import type {
  GetServerSidePropsContext,
  NextApiRequest,
  NextApiResponse,
} from 'next';
import type { CookieSerializeOptions } from 'cookie';
import { serialize, parse } from 'cookie';

export type ServerRequest = GetServerSidePropsContext['req'] | NextApiRequest;

export function getCookieServer(
  req: ServerRequest,
  cookieName: string,
): string {
  const cookies = parseCookiesServer(req);

  return cookies[cookieName];
}

export function parseCookiesServer(req: ServerRequest): {
  [key: string]: string;
} {
  // For API Routes we don't need to parse the cookies.
  if (req?.cookies) return req.cookies;

  // For pages we do need to parse the cookies.
  const cookie = req?.headers?.cookie;

  return parse(cookie || '');
}

export function removeCookieServer(res: NextApiResponse, name: string): void {
  const authCookie = serialize(name, '', {
    maxAge: -1,
    path: '/',
  });

  res.setHeader('Set-Cookie', authCookie);
}

export function setCookieServer(
  res: NextApiResponse,
  cookie: { name: string; value: string; options: CookieSerializeOptions },
): void {
  res.setHeader(
    'Set-Cookie',
    serialize(cookie.name, cookie.value, cookie.options),
  );
}
