import { useMemo } from 'react';

export function useQueryParam(name: string): string | null {
  if (typeof window === 'undefined') {
    return null;
  }

  return useMemo(() => {
    const params = new URLSearchParams(window.location.search);

    return params.get(name);
  }, [window.location.search]);
}
