import { useMemo } from 'react';
import { getStateCookie } from 'lib/cookies/state-cookie';
import { useQueryParam } from 'hooks/useQueryParam';

// state inheritance order
// backoffice state -> query param state -> cookie state

export function useCurrentState(
  pageStates?: string[] | any,
): string | undefined {
  if (typeof window === 'undefined') {
    return undefined;
  }

  const stateCookie = getStateCookie();
  const queryState = useQueryParam('state');

  return useMemo(() => {
    const queryStateCode = queryState?.toUpperCase();

    return pageStates?.[0] || queryStateCode || stateCookie;
  }, [pageStates, queryState, stateCookie]);
}
