import React from 'react';
import Head from 'next/head';

import { useWordPressContext } from 'contexts/wordpress-provider';

const PresenceJson: React.FC = () => {
  const {
    seoSettings: { presence, siteUrl },
  } = useWordPressContext();

  if (!presence) {
    return null;
  }

  const details = {
    '@context': 'https://schema.org/',
    '@type': presence?.type,
    url: siteUrl,
    ...(presence?.logo ? { logo: presence.logo } : {}),
  };

  return React.createElement(
    Head,
    null,
    React.createElement('script', {
      type: 'application/ld+json',
      key: 'leafwell-presence-json-ld',
      dangerouslySetInnerHTML: {
        __html: JSON.stringify(details),
      },
    }),
  );
};

export default PresenceJson;
